::-webkit-scrollbar {
   width: 5px;
}

::-webkit-scrollbar-thumb {
   background: rgb(156 163 175 / var(--tw-bg-opacity));
}

.body-text-preview {
   display: -webkit-box;
   overflow: hidden;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   visibility: visible;
 }
@import './base.css';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html, body {
  width: 100%;
  height: 100%;
}

body{
  display: flex;
  justify-content: center;
  align-items: center;
}

.alpha-chat-widget {
  @apply font-sans text-black bg-white;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animation-move-up{
  position: relative;
  animation: moveUpAnimation 300ms ease-in;
  -webkit-animation: moveUpAnimation 300ms ease-in;
  -moz-animation: moveUpAnimation 300ms ease-in;
}

.animation-move-down{
  position: relative;
  animation: moveDownAnimation 300ms ease-in;
  -webkit-animation: moveDownAnimation 300ms ease-in;
  -moz-animation: moveDownAnimation 300ms ease-in;
}

.animation-move-right{
  position: relative;
  animation: moveRightAnimation 300ms ease-in;
  -webkit-animation: moveRightAnimation 300ms ease-in;
  -moz-animation: moveRightAnimation 300ms ease-in;
}

.animation-in{
  position: relative;
  animation: inAnimation 300ms ease-in;
  -webkit-animation: inAnimation 300ms ease-in;
  -moz-animation: inAnimation 300ms ease-in;
}

.animation-out{
  animation: outAnimation 300ms ease-out;
  -webkit-animation: outAnimation 300ms ease-out;
  -moz-animation: outAnimation 300ms ease-out;
}

.animation-slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
}

.animation-slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-30 21:14:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
}


@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes moveUpAnimation {
  0% {
    opacity: 0;
    bottom: -40px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes moveDownAnimation {
  0% {
    opacity: 0;
    top: -40px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes moveRightAnimation {
  0% {
    opacity: 0;
    right: -20px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

.box-shadow-main{
  box-shadow: 1px 1px 8px 2px #7d7d7d47;
  -webkit-box-shadow: 1px 1px 8px 2px #7d7d7d47;
  -moz-box-shadow: 1px 1px 8px 2px #7d7d7d47;
}

.iframe-side-notif{
  height: 100px;
  width: 300px;
}

.root-chat-widget{
  width: 100%;
}

.root-side-notif{
  display: flex;
  flex-direction: row;
  justify-content: end;
  /* margin-right: 20px; */
  margin: 0;
  width: 100%;
  align-self: end;
}

.root-side-notif-mobile{
  padding: 0 !important;
}

.root-side-notif-desktop{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 18px;
}

#side-notif{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -35px;
  padding-left: 16px;
  padding-right: 50px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
